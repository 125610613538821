// src/components/LocationControl.js
import React, { useContext } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LocationContext, UpdateLocationContext } from '../context/LocationContext';

const LocationControl = () => {
  const { activeLocation, locations } = useContext(LocationContext);
  const { setActiveLocation } = useContext(UpdateLocationContext);

  const handleSelectLocation = (location) => {
    setActiveLocation(location);
  };

  const currentLocation = locations.find(location => location.id === activeLocation.id);

  return (
    <UncontrolledButtonDropdown nav>
      <DropdownToggle nav>
      	<i className="nav-link-icon pe-7s-map-marker"></i>{currentLocation ? currentLocation.display_name : 'Select Location'}
        <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
      </DropdownToggle>
      <DropdownMenu>
        {locations.length > 0 ? (locations.map(location => (
          <DropdownItem key={location.id} onClick={() => handleSelectLocation(location)}>
            {location.display_name}<br/>
			      ({location.address_line1})
          </DropdownItem>
        ))) : (
          <DropdownItem key="newlocation"
            onClick={() => {
              window.location.href = `/account/locations/`
            }}
          >
            Create Your First Location...
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default LocationControl;
