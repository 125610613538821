import React, { useEffect, useState, Suspense, lazy, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Loader from "react-loaders";

import { LocationProvider } from './context/LocationContext';
import { AccountProvider } from './context/AccountContext';

import supabase from './lib/supabase';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/general.css';

import { ToastContainer } from "react-toastify";

import AppHeader from "./Layout/AppHeader/";
import AppSidebar from "./Layout/AppSidebar/";
import AppFooter from "./Layout/AppFooter/";
import ThemeOptions from "./Layout/ThemeOptions/";


//Public pages
const Login = lazy(() => import('./public/login'));
const Register = lazy(() => import('./public/register'));
const Home = lazy(() => import('./public/index'));

//Account pages
const AdminHome = lazy(() => import('./pages/index'));
const InventoryLedger = lazy(() => import('./pages/inventory/ledger'));
const InventoryLocations = lazy(() => import('./pages/inventory/zones/inventory_zones'));
const InventoryLocationComponents = lazy(() => import('./pages/inventory/zones/inventory_zone_components'));
const IngredientsList = lazy(() => import('./pages/ingredients/ingredients'));
const RecipeList = lazy(() => import('./pages/ingredients/recipes'));
const RecipeDetail = lazy(() => import('./pages/ingredients/recipeDetail'));
const BrandsList = lazy(() => import('./pages/admin/ingredients/brands'));
const SuppliersList = lazy(() => import('./pages/admin/ingredients/suppliers'));
const AccountInfoEdit = lazy(() => import('./pages/account/account'));
const LocationsList = lazy(() => import('./pages/account/locations'));
const InventoryFullCount = lazy(() => import('./pages/inventory/counts/full_count'));
const InventorySingleCount = lazy(() => import('./pages/inventory/counts/single_count'));
const InventoryCreateOrder = lazy(() => import('./pages/inventory/ordering/create_order'));
const InventoryReceiveOrder = lazy(() => import('./pages/inventory/ordering/receive_order'));


const App = () => {
  const history = useHistory();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      //console.log('Initial session:', session);
      setSession(session);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      //console.log('Auth state change:', event, session);
      setSession(session);
    });

    return () => authListener.subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleMagicLinkRedirect = async () => {
      //console.log('Handling magic link redirect');
      let hash = window.location.hash.substring(1);
      // Remove leading slash if present
      if (hash.startsWith('/')) {
        hash = hash.substring(1);
      }

      const params = new URLSearchParams(hash);
      //console.log(hash);

      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      //console.log('Access Token:', access_token);
      //console.log('Refresh Token:', refresh_token);

      if (access_token && refresh_token) {
        const { error } = await supabase.auth.setSession({ access_token, refresh_token });

        if (error) {
          console.error('Error setting session:', error.message);
        } else {
          //console.log('Session set, redirecting to dashboard');
          history.push('/dashboard'); // Redirect to the dashboard or another authenticated page
        }
      } else {
        //console.log('Tokens not found in URL');
      }
    };

    handleMagicLinkRedirect();
  }, [history]);

  return (
    <LocationProvider>
      <Router>
        {session && session.user ? (
          <AccountProvider>
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="ball-pulse-rise" />
                    </div>
                    <h6 className="mt-5">
                      Loading...
                    </h6>
                  </div>
                </div>
              }
            >
              <Fragment>
                <ThemeOptions />
                <AppHeader />
                <div className="app-main">
                  <AppSidebar />
                  <div className="app-main__outer">
                    <div className="app-main__inner">

                        <Route exact path="/inventory" component={InventoryLedger} />

                        <Route exact path="/inventory/full_count" component={InventoryFullCount} />
                        <Route exact path="/inventory/single_count" component={InventorySingleCount} />

                        <Route exact path="/inventory/new_order" component={InventoryCreateOrder} />
                        <Route exact path="/inventory/receive_order" component={InventoryReceiveOrder} />

                        <Route exact path="/inventory/inventory_zones" component={InventoryLocations} />
                        <Route exact path="/inventory/inventory_zones/detail/:inventory_zone_id" component={InventoryLocationComponents} />

                        <Route exact path="/ingredients" component={IngredientsList} />
                        <Route exact path="/ingredients/recipes" component={RecipeList} />
                        <Route path="/ingredients/recipes/detail/:item_id" component={RecipeDetail} />

                        <Route exact path="/admin/brands" component={BrandsList} />
                        <Route exact path="/admin/suppliers" component={SuppliersList} />

                        <Route exact path="/account" component={AccountInfoEdit} />
                        <Route exact path="/account/locations" component={LocationsList} />

                        <Route exact path="/" component={AdminHome} />
                    </div>
                    <AppFooter />
                  </div>
                </div>
                <ToastContainer/>
              </Fragment>
            </Suspense>
          </AccountProvider>
        ) : (
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise" />
                  </div>
                  <h6 className="mt-5">
                    Loading...
                  </h6>
                </div>
              </div>
            }
          >
            <Switch>
              <Route path="/register" exact component={Register} />
              <Route path="/login" exact component={Login} />
              <Route path="/" exact component={Home} />
            </Switch>
          </Suspense>
        )}
      </Router>
    </LocationProvider>
  );
};

export default App;
