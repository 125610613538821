// src/context/LocationContext.js
import React, { createContext, useState, useEffect } from 'react';
import supabase from '../lib/supabase';

const LocationContext = createContext();
const UpdateLocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(() => {
    // Load the initial location from local storage
    const savedLocationId = localStorage.getItem('activeLocation');
    //console.log(savedLocationId);
    return savedLocationId ? JSON.parse(savedLocationId) : {id:0,name:''};
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // Fetch locations from Supabase (only once per session)
    const fetchLocations = async () => {
      const { data, error } = await supabase
	  	.from('locations')
	  	.select('*')
		  .eq('archived',0)
      .order('ordinal', { ascending: true });
      //console.log(data,error);
      if (!error) {
        setLocations(data);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      const foundItem = locations.find(item => item.id === activeLocation.id);
      if (!foundItem) setActiveLocation(locations[0]); //If we didn't find the activeLocation, we need to default to the first location in the array so we do'nt break the page displays that depend on activeLocation.id
    } //else there are no locations anyhow, so we have nothing to select
  },[locations,activeLocation]);

  useEffect(() => {
    // Save the activeLocation to local storage whenever it changes
    if (activeLocation !== null) {
      //console.log("Setting location ID",activeLocation);
      localStorage.setItem('activeLocation', JSON.stringify(activeLocation));
    } else {
      localStorage.removeItem('activeLocation');
    }
  }, [activeLocation]);

  return (
    <LocationContext.Provider value={{ activeLocation, locations }}>
      <UpdateLocationContext.Provider value={{setActiveLocation, setLocations}}>
        {children}
      </UpdateLocationContext.Provider>
    </LocationContext.Provider>
  );
};

export { LocationContext, UpdateLocationContext, LocationProvider };
