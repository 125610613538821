import React, { Fragment, useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { toast, Bounce } from "react-toastify";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';

const UserBox = () => {
  const {account, logout} = useContext(AccountContext);
  //console.log("Account in userBox",account);

    const notify2 = () =>
      toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
    });
    
    const handleLogout = () => {
      console.log("Logout Clicked");
      logout();
    };


  return (
    <Fragment>
      <div className="header-btn-lg pe-0" style={{ paddingLeft: '5px' }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle color="Dark" className="p-0 d-flex align-items-center">
                <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} />
                <div className="widget-content-left ms-3 header-user-info">
                  <div className="widget-heading" style={{textAlign: 'left', fontSize: '1rem'}}>{account?.fname} {account?.lname}</div>
                  <div className="widget-subheading" style={{textAlign: 'left'}}>{account?.company_name}</div>
                </div>
              </DropdownToggle>
              <DropdownMenu style={{width: '15rem'}}>
                  <PerfectScrollbar>
                    <Nav vertical>
                      <NavItem>
                        <NavLink href="/account">
                          My Account
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="/account/locations">
                          Locations
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="#" onClick={handleLogout}>
                          Log Out
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </PerfectScrollbar>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
