export const DashboardsNav = (membershipLevel) => [
  {
    icon: "gauge-high",
    label: "Dashboard",
    to: "/dashboard",
  },
  ...(membershipLevel >= 2 ? [{
    icon: "chart-line",
    label: "Reports",
    content: [
      {
        label: "FB Ad Stats",
        to: "/reports/fb_ad_stats/",
      },
      {
        label: "Staffing Forecast",
        to: "/reports/staffing_forecast/",
      },
      {
        label: "Pricing & Profits",
        to: "/reports/pricing_and_profits/",
      },
      {
        label: "Missing Modifier Amounts",
        to: "/reports/missing_modifiers/",
      },
    ],
  }] : []),
];

// Similar updates for other navigation items if needed
export const InventoryNav = (membershipLevel) => [
  {
    icon: "list-check",
    label: "Count",
    content: [
      {
        label: "Full Count",
        to: "/inventory/full_count/",
      },
      {
        label: "Single Count",
        to: "/inventory/single_count/",
      }
    ]
  },
  {
    icon: "cart-shopping",
    label: "Ordering",
    content: [
      {
        label: "Create New Order",
        to: "/inventory/new_order/",
      },
      {
        label: "Receive Order",
        to: "/inventory/receive_order/",
      },
    ],
  },
  {
    icon: "bars",
    label: "Ledger",
    to: "/inventory/",
  },
  {
    icon: "magnifying-glass-location",
    label: "Inventory Zones",
    to: "/inventory/inventory_zones/",
  },
];
export const RecipesNav = (membershipLevel) => [
  ...(membershipLevel >= 2 ? [{
    icon: "rectangle-list",
    label: "Recipes",
    to: "/ingredients/recipes/",
  }] : []),
  {
    icon: "mortar-pestle",
    label: "Ingredients",
    to: "/ingredients/",
  },
  ...(membershipLevel >= 2 ? [{
    icon: "pen-to-square",
    label: "Modifiers",
    to: "/ingredients/modifiers/",
  }] : []),
];
export const MenuNav = (membershipLevel) => 
  (membershipLevel >= 2 ? [
    {
      icon: "pen-to-square",
      label: "Menus",
      to: "/menu/dashboard/",
    },
  ] : []);
export const AppNav = (membershipLevel) =>
  (membershipLevel >= 2 ? [
    {
      icon: "list-ul",
      label: "App Stuff",
      to: "/inventory/full_count/",
    },
  ] : []);
export const AdminNav = (membershipLevel) => [
  {
    classNameIcon: "metis-hidden",
    label: "Ingredient Admin",
    content: [
      {
        icon: "location-crosshairs",
        label: "Brands",
        to: "/admin/brands/",
      },
      {
        icon: "location-crosshairs",
        label: "Suppliers",
        to: "/admin/suppliers/",
      },
    ]
  },
  ...(membershipLevel >= 2 ? [{
    icon: "location-crosshairs",
    label: "Checklists",
    to: "/admin/checklists/",
  }] : []),
  ...(membershipLevel >= 2 ? [{
    icon: "gauge-high",
    label: "Recipe Admin",
    content: [
      {
        icon: "location-crosshairs",
        label: "Bake Groups",
        to: "/admin/bake_groups/",
      },
      {
        icon: "location-crosshairs",
        label: "Prep Groups",
        to: "/admin/prep_groups/",
      },
    ],
  }] : []),
];
