import React, { Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DashboardsNav,
  InventoryNav,
  MenuNav,
  RecipesNav,
  AppNav,
  AdminNav,
} from "./NavItems";
import { AccountContext } from "../../context/AccountContext";

const Nav = (props) => {
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  const {account} = useContext(AccountContext);

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Back Office</h5>
      <MetisMenu content={DashboardsNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(InventoryNav(account?.membership_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Inventory</h5>
        <MetisMenu content={InventoryNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      <h5 className="app-sidebar__heading">Recipes & Ingredients</h5>
      <MetisMenu content={RecipesNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(MenuNav(account?.membership_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Menus</h5>
        <MetisMenu content={MenuNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {(AppNav(account?.membership_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">App Admin</h5>
        <MetisMenu content={AppNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {(AdminNav(account?.membership_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Admin</h5>
        <MetisMenu content={AdminNav(account?.membership_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu metis-hidden" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
    </Fragment>
  );
};

export default withRouter(Nav);
