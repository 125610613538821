import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import supabase from '../lib/supabase';

const AccountContext = createContext();
const UpdateAccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);  // Add state to hold the Supabase user object
  const history = useHistory();

  useEffect(() => {
    // Fetch the user session and account info from Supabase
    const fetchUser = async () => {
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        const user = session?.user;
        user.access_token = session?.access_token;
        setUser(user);  // Set the user object from session
        if (user) {
          const { data, error } = await supabase
            .from('userinfo')
            .select('*, oauth_square(merchant_id)')
            .eq('user_id', user.id)
            .single();
			    //console.log(data,data?.oauth_square?.merchant_id);
          if (!error) {
            data.square_connected = (data?.oauth_square?.merchant_id !== undefined && data?.oauth_square?.merchant_id !== null);
            setAccount(data);
          } else {
            console.error('Error fetching user info:', error);
          }
        }
      });
    };

    fetchUser();
  }, []);

  const logout = async () => {
    console.log("Logging out!");
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      history.push('/login');
    }
  };

  return (
    <AccountContext.Provider value={{ account, user, setAccount, logout }}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContext, UpdateAccountContext, AccountProvider };
